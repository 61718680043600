
const mapStateToProps = (state ) => { 
    return {
        id_com_company: state.constants.id_com_company,
        id_com_user: state.constants.id_com_user,
        api_url: state.constants.api_url,
        upload_url:state.constants.upload_url,
        api_media:state.constants.api_media,
        email:state.constants.email,
        id_com_manager_email:state.constants.email,
        username:state.constants.username,
        id_com_user_type: state.constants.id_com_user_type,
        loginToken:state.constants.loginToken,
        ordercart:state.ordercart.cart,
        modalVisible:state.modals.modalVisible,
        selectedId:state.modals.selectedId,
        selectedTable:state.modals.selectedTable,
        expandAll:state.modals.expandAll,
        id_com_department:state.constants.id_com_department,
        id_com_manager:state.constants.id_com_manager,
        depots:state.constants.depots,
        components:state.constants.components,
        approvall_level:state.constants.approvall_level,
        isFirstRow:state.modals.isFirstRow,
        user_image:state.constants.user_image,
        id_com_department_name:state.constants.id_com_department_name,
        id_com_user_type_name:state.constants.id_com_user_type_name,
        birthday:state.constants.birthday,

    }
}

export default mapStateToProps;
 