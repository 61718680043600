import actions from './actions';

const initialState = { 
    api_url : 'https://tunaylar.mitrodev.app',
    upload_url:'https://tunaylar.mitrodev.app/upload/',
    // api_url : 'http://localhost', 
    // upload_url:'http://localhost/upload/',
    api_media : 'https://s3.eu-central-1.amazonaws.com/tunaylar-api.static.mitrodev.app/', 
    isLoggedIn:false,
    id_com_user :'',
    id_com_company:'',
    id_com_company_type:'',
    loginToken:'',
    id_com_user_type:'',
    email:'',
    password:'',
    username:'',
    id_com_manager:'',
    id_com_manager_email:'',
    approvall_level:'',
    id_com_department:'',
    auth_component:'',
    components:[],
    auth_depot:'',
    depots:[],
    themeName:'defaultTheme',
    user_image:'/',
    id_com_department_name:'',
    id_com_user_type_name:'',
    user_comment:'',
    birthday:'',

}  

export default function constants  (state = initialState,action)  { 
    switch(action.type) {
          
        case actions.LOG_OUT:  
          window.location.href = '/';
          localStorage.clear();

          return  state=initialState;

        case actions.ADD_APPROVALL_LEVEL:  
        return  {...state, approvall_level:action.approvall_level}
        
         case actions.ADD_LOGIN_TOKEN:  
         return  {...state, loginToken:action.loginToken}
         
         case actions.LOGGED_IN:  
         return  {...state, isLoggedIn:true}

         case actions.ADD_ID_COM_USER:  
         return  {...state, id_com_user:action.id_com_user}
         
         case actions.ADD_EMAIL:  
         return  {...state, email:action.email}
         
         case actions.ADD_PASSWORD:  
         return  {...state, password:action.password}

         case actions.ADD_ID_COM_COMPANY:  
         return  {...state, id_com_company:action.id_com_company}  

         case actions.ADD_ID_COM_COMPANY_TYPE:  
         return  {...state, id_com_company_type:action.id_com_company_type}  

         case actions.ADD_USERNAME:  
         return  {...state, username:action.username}  

         case actions.ADD_MANAGER:  
         return  {...state, id_com_manager:action.id_com_manager}  

         case actions.ADD_MANAGER_EMAIL:  
         return  {...state, id_com_manager_email:action.id_com_manager_email} 
         
         case actions.ADD_DEPARTMENT:  
         return  {...state, id_com_department:action.id_com_department}  

         case actions.ADD_AUTH_DEPOT:  
         return  {...state, auth_depot:action.auth_depot}  

         case actions.ADD_AUTH_COMPONENT:  
         return  {...state, auth_component:action.auth_component}  

         case actions.GET_DEPOTS:  
         return  {...state, depots:action.depots}  

         case actions.GET_COMPONENTS:  
         return  {...state, components:action.components}  

         case actions.ADD_USER_TYPE:  
         return  {...state, id_com_user_type:action.id_com_user_type}     
         
         case actions.ADD_USER_IMAGE:  
         return  {...state, user_image:action.user_image}  

         case actions.ADD_DEPARTMENT_NAME:  
         return  {...state, id_com_department_name:action.id_com_department_name}  

         case actions.ADD_USER_TYPE_NAME:  
         return  {...state, id_com_user_type_name:action.id_com_user_type_name}  

         case actions.ADD_BIRTHDAY:  
         return  {...state, birthday:action.birthday}  


         default:
               return state
    }
}

;
