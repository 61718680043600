const actions = {
      REMOVE_CONSTANT: 'REMOVE_CONSTANT',
      LOG_OUT: 'LOG_OUT',
      LOGGED_IN:'LOGGED_IN',
      ADD_USER_TYPE:'ADD_USER_TYPE',
      ADD_EMAIL:'ADD_EMAIL',
      ADD_PASSWORD:'ADD_PASSWORD',
      ADD_ID_COM_COMPANY:'ADD_ID_COM_COMPANY',
      ADD_ID_COM_USER:'ADD_ID_COM_USER',
      ADD_LOGIN_TOKEN:'ADD_LOGIN_TOKEN',
      ADD_USERNAME:'ADD_USERNAME',
      ADD_MANAGER: 'ADD_MANAGER',
      ADD_DEPARTMENT: 'ADD_DEPARTMENT',
      ADD_AUTH_DEPOT: 'ADD_AUTH_DEPOT',
      GET_DEPOTS: 'GET_DEPOTS',
      ADD_AUTH_COMPONENT: 'ADD_AUTH_COMPONENT',
      GET_COMPONENTS: 'GET_COMPONENTS',
      ADD_ID_COM_COMPANY_TYPE:'ADD_ID_COM_COMPANY_TYPE',
      ADD_APPROVALL_LEVEL:'ADD_APPROVALL_LEVEL',
      ADD_MANAGER_EMAIL:'ADD_MANAGER_EMAIL',
      ADD_USER_IMAGE:'ADD_USER_IMAGE',
      ADD_DEPARTMENT_NAME:'ADD_DEPARTMENT_NAME',
      ADD_USER_TYPE_NAME:'ADD_USER_TYPE_NAME',
      ADD_BIRTHDAY:'ADD_BIRTHDAY', 
      _Log_Out : () => ({
            type : actions.LOG_OUT,
            }),
      _Logged_In : () => ({
            type : actions.LOGGED_IN,
            }),
      _Add_Id_Com_User_Type : (id_com_user_type) => ({
            type : actions.ADD_USER_TYPE,
            id_com_user_type:id_com_user_type
            }),
      _Add_Email : (email) => ({
            type : actions.ADD_EMAIL,
            email:email
            }),     
      _Add_Manager_Email : (id_com_manager_email) => ({
            type : actions.ADD_MANAGER_EMAIL,
            id_com_manager_email:id_com_manager_email
            }),  
      _Add_Password : (password) => ({
            type : actions.ADD_PASSWORD,
            password:password
            }),
      _Add_Id_Com_Company : (id_com_company) => ({
            type : actions.ADD_ID_COM_COMPANY,
            id_com_company:id_com_company
            }),
      _Add_Id_Com_User : (id_com_user) => ({
            type : actions.ADD_ID_COM_USER,
            id_com_user:id_com_user
            }),
      _Add_Id_Com_Department : (id_com_department) => ({
            type : actions.ADD_DEPARTMENT,
            id_com_department:id_com_department
            }),
            
      _Add_Id_Com_Company_Type : (id_com_company_type) => ({
            type : actions.ADD_ID_COM_COMPANY_TYPE,
            id_com_company_type:id_com_company_type
            }),
      _Add_Id_Com_Manager : (id_com_manager) => ({
            type : actions.ADD_MANAGER,
            id_com_manager:id_com_manager
            }),
      _Add_Login_Token : (loginToken) => ({
            type : actions.ADD_LOGIN_TOKEN,
            loginToken:loginToken
            }),
      _Add_Username : (username) => ({
            type : actions.ADD_USERNAME,
            username:username
            }),
      _Add_Auth_Depot : (auth_depot) => ({
            type : actions.ADD_AUTH_DEPOT,
            auth_depot:auth_depot
            }),
      _Get_Depots : (depots) => ({
            type : actions.GET_DEPOTS,
            depots:depots
            }),
      _Add_Auth_Component : (auth_component) => ({
            type : actions.ADD_AUTH_COMPONENT,
            auth_component:auth_component
            }),
      _Get_Components : (components) => ({
            type : actions.GET_COMPONENTS,
            components:components
            }),
      _Remove_Constant : () => ({
            type : actions.REMOVE_CONSTANT,
            }),   
      _Add_Approvall_Level : (approvall_level) => ({
            type : actions.ADD_APPROVALL_LEVEL,
            approvall_level:approvall_level
            }),    
      _Add_User_Image : (user_image) => ({
            type : actions.ADD_USER_IMAGE,
            user_image:user_image
            }),  
      _Add_Department_Name : (id_com_department_name) => ({
            type : actions.ADD_DEPARTMENT_NAME,
            id_com_department_name:id_com_department_name
            }),  
      _Add_User_Type_Name : (id_com_user_type_name) => ({
            type : actions.ADD_USER_TYPE_NAME,
            id_com_user_type_name:id_com_user_type_name
            }),  
      _Add_Birthday : (birthday) => ({
            type : actions.ADD_BIRTHDAY,
            birthday:birthday
            }),  

            
    };

export default actions;
 
 